


























import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";

@Component
export default class EpisodeQuestionForm extends Vue {
  public question = {
    id: 0,
    question: ''
  };
  public episode = {};
  public settings: Settings;
  public episodeId: number;

  public constructor() {
    super();
    this.settings = new Settings();
    this.question.id = +this.$route.params.questionId;
    this.episodeId = +this.$route.params.episodeId;
  }

  public loadData(): void {
    this.axios.get(this.settings.settings.host + '/api/episodes/' + this.episodeId, { //this.id.toString(),{
      params: {},
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.episode = response.data;
    });
    if (this.question.id > 0) {
      this.axios.get(this.settings.settings.host + '/api/episode-questions/' + this.question.id, { //this.id.toString(),{
        params: {},
        headers: {
          Authorization: 'Bearer ' + Auth.getToken()
        }
      }).then(response => {
        this.question = response.data;
      });
    }

  }

  public edit(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    if (this.question.id > 0) {
      this.axios.put(
          this.settings.settings.host + '/api/episode-questions/' + this.question.id,
          this.question,
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Pytanie zostao edytowane'
        });
      })
    } else {
      this.axios.post(
          this.settings.settings.host + '/api/episode-questions',
          {
            'question': this.question.question,
            'episode': this.episode['@id']
          },
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Pytanie zostało dodane'
        });
      })
    }
  }

  created(): void {
    this.loadData();
  }
}
